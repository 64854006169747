import { ChakraProvider } from "@chakra-ui/react";
import { extendTheme } from "@chakra-ui/react";

import HeapProvider from "@components/HeapProvider";
import Layout from "@components/Layout";
import type { AppProps } from "next/app";

import "../styles/globals.css";

// This mostly should block dark mode for now until we have bandwidth to test it
const config = {
  initialColorMode: "light",
  useSystemColorMode: false,
};

const theme = extendTheme({
  config,
  components: { Input: { defaultProps: { variant: "outline" } } },
});

function MyApp({ Component, pageProps }: AppProps) {
  return (
    <>
      <HeapProvider />
      <ChakraProvider theme={theme}>
        <Layout>
          <Component {...pageProps} />
        </Layout>
      </ChakraProvider>
    </>
  );
}

export default MyApp;
